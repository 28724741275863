export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/pinocchios-logo.png',
    banner_img_root : '/img/pinocchios-banner.png',
    resid : '',
    key_value : 'pinocchiosloveland',
    secret_value : 'pinocchiosloveland',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3038.047745355046!2d-105.00597348510571!3d40.40779306410797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876eace602db834f%3A0xe1f9ad10bb3f6b56!2s1455%20Rocky%20Mountain%20Ave%2C%20Loveland%2C%20CO%2080538%2C%20USA!5e0!3m2!1sen!2sin!4v1582797602551!5m2!1sen!2sin',
    facebook_link:'#',
    RestaurantName:"Pinocchios Loveland Order Online"
    
};
